import DealerPreferredOEMSection from "components/DealerForm/components/DealerPreferredOEMSection";
import LoanerVehicleSection from "components/DealerForm/components/LoanerVehicleSection";
import DealerScheduleSection from "components/DealerForm/components/DealerScheduleSection";
import React from "react";

const AppointmentSchedulerConfigurationForm = () => {
  return (
    <>
      <h1> Dealer Preferred Makes</h1>
      <DealerPreferredOEMSection />
      <h1> Dealer Schedule </h1>
      <DealerScheduleSection />
      <h1> Loaner Vehicles</h1>
      <LoanerVehicleSection />
    </>
  );
};

export default AppointmentSchedulerConfigurationForm;
