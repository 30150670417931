import React, { useEffect, useState, useMemo } from "react";
import { IconButton, Button, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import styles from "./styles.module.scss";
import { LoanerVehicle } from "api/types/loanerVehicle";
import { useAppSelector } from "store";
import postDealerLoanerVehicle from "api/postDealerLoanerVehicle";

const daysOfWeek = [
  "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
];

const LoanerVehicleSection = () => {
  const { dealerLoanerVehicles, dealer } = useAppSelector(state => state.dealerForm);
  const [loanerVehicles, setLoanerVehicles] = useState<LoanerVehicle | undefined>(dealerLoanerVehicles);

  useEffect(() => {
    setLoanerVehicles(dealerLoanerVehicles);
  },[dealerLoanerVehicles]);

  const transformData = useMemo(() =>
    daysOfWeek.map(day => ({
      day,
      vehicles: loanerVehicles?.[day]?? 0
    })),
    [loanerVehicles]
  );

  const revertData = (data: { day: string; vehicles: number }[], dealerID: number): LoanerVehicle => {
    const result: LoanerVehicle = {
      DealerID: dealerID,
      Sunday: 0,
      Monday: 0,
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0,
      Saturday: 0,
    };
    data.forEach(({ day, vehicles }) => {
      if (day in result) {
        result[day as keyof LoanerVehicle] = vehicles;
      }
    });
    return result;
  };

  const handleVehicleInputChange = (index: number, value: string) => {
    const num = Number(value);
    if (!isNaN(num) && num >= 0) {
      const updatedVehicles = [...transformData];
      updatedVehicles[index].vehicles = num;
      setLoanerVehicles(revertData(updatedVehicles, dealer.ID));
    }
  };

  const handleVehicleChange = (index: number, change: number) => {
    const updatedVehicles = [...transformData];
    const newNum = updatedVehicles[index].vehicles + change;
    if (newNum >= 0) {
      updatedVehicles[index].vehicles = newNum;
      setLoanerVehicles(revertData(updatedVehicles, dealer.ID));
    }
  };

  const handleSubmit = () => {
    if (loanerVehicles) {
      const revertedData = revertData(transformData, dealer.ID);
      postDealerLoanerVehicle(revertedData);
    }
  };

  return (
    <div className={styles.container}>
      <table className={styles.daysTable}>
        <thead>
          <tr>
            <th>Day</th>
            <th>Loaner Vehicles</th>
          </tr>
        </thead>
        <tbody>
          {transformData.map((day, index) => (
            <tr key={index} className={index % 2 === 0 ? styles.evenRow : styles.oddRow}>
              <td>{day.day}</td>
              <td className={styles.vehiclesColumn}>
                <TextField
                  type="number"
                  value={day.vehicles}
                  onChange={(e) => handleVehicleInputChange(index, e.target.value)}
                  inputProps={{ min: 0 }}
                />
                <div className={styles.actionsContainer}>
                  <IconButton
                    onClick={() => handleVehicleChange(index, 1)}
                    color="primary"
                    aria-label="Add vehicle"
                  >
                    <AddIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleVehicleChange(index, -1)}
                    color="secondary"
                    aria-label="Remove vehicle"
                    disabled={day.vehicles === 0}
                  >
                    <RemoveIcon />
                  </IconButton>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.submitContainer}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Update
        </Button>
      </div>
    </div>
  );
};

export default LoanerVehicleSection;
